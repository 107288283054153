import { PresaleToken } from './types/types'

const PresaleTokens: PresaleToken[] = [
  {
    address: '0x88c0cE2Bf2aDdF1B9503fC38b44721FD675595B1',
    banner: '/banner.png',
    claimTime:1713830400,
    createdBy: '0x222fA00bE6f06f7e9bC86A009f79Eb48Dc6C695D',
    description: 'ROBIN serves as your gateway to launching CRC20 tokens and NFTs, staking NFTs, and more. The ROBIN token will serve as the main utility, unlocking exclusive benefits.',
    discordUrl: 'https://discord.gg/xWCY5wa5qE',
    endTime:1713830400,
    externalUrl: 'https://robin.space',
    featured:true,
    hardCap:0,
    id: '0x4231d9DfAF5F8D94CBCc5729304eeA7DAb115C12',
    image:'/logo.png',
    limitPerWallet:0,
    mintFunction:'deposit',
    name: 'ROBIN Token',
    presaleAddress:'0x4231d9DfAF5F8D94CBCc5729304eeA7DAb115C12',
    roadmap:[
      {
        description:'Dapp and ROBIN token presale launch.',
        name: 'Phase 1. Q2 2024'
      }
    ],
    saleSupply: 500000000,
    standard:'DN404',
    startTime:1713571200,
    status: 'verified',
    supply: 1000000000,
    symbol: 'ROBIN',
    telegram: '',
    totalContributed:0,
    twitterUsername: 'robinCronos',
  }
  /*
  {
    id: 3,
    name: 'Titan 3',
    symbol: 'TITAN',
    description: 'A collection of 10,000 Titan replicants where NFTs and CRC20 tokens converge, offering limitless possibilities.',
    address: '0xba2580f24fbca76637ba35dd883a9ac68ed64f39',
    presaleAddress: '0x0826A35F6c55650a7c08a3E325f6435C8d6419fb',
    status: 'verified',
    featured: true,
    supply: 10000,
    saleSupply: 500,
    image: '/logo.png',
    banner:'https://www.crystalinks.com/Titanomachy1.jpg',
    standard: 'CRC404',
    mintFunction: 'deposit',
    limitPerWallet: 15,
    startTime: 1712120139,
    endTime: 1712120379,
    claimTime: 1712120379,
    discordUrl: 'http://discord.gg/2W64aWvy',
    twitterUsername: 'croTitans',
    externalUrl:'https://cronustitan.com',
    roadmap: [
              {
                name: 'Phase 1 - Q2 2024',
                description: 'Utility token Mint, launch beta platform and growth',
              },
              {
                  name: 'Phase 2 - Q3/4 2024',
                  description: 'Presale automation, user and devs tools. Follow our social media for more details.',
              },
              {
                  name: 'Phase 3 - Q1 2025',
                  description: 'Moon 🚀',
              },
            ]
  }
  */
]

export default PresaleTokens

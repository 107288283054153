import useMediaQuery from 'hooks/useMediaQuery'
import React from 'react'
import Marquee from 'react-fast-marquee'
import { styled } from 'stitches.config'
import { Text } from 'components/primitives'
import Img from 'components/primitives/Img'
import Link from 'next/link'

const TagWrapper = styled('div', {
  background:'$gray2',
  display: 'flex',
  padding: '8px 12px',
  height: 38,
  gap:'$1',
  justifyContent: 'start',
  alignItems: 'center',
  borderRadius: 8,
  marginRight: 8,
  overflow:'hidden',
  '@sm': {
    padding: '12px 24px',
    height: 46,
    marginRight: 12,
  },
});

const StyledMarquee = styled(Marquee, {
  width: '100%',
  maxWidth: 1063,
  maskImage: 'linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent)',
  WebkitMaskImage: 'linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent)',
  borderRadius: 12,
});


const newsItems = [
  {
    key: 'coingecko',
    name: 'CoinGecko',
    size: 24,
    url: 'https://www.coingecko.com/en/coins/robin-on-cronos',
  },
  {
    key: 'vvs',
    name: 'VVS',
    url:'https://vvs.finance/swap?inputCurrency=CRO&outputCurrency=0x664E4b17EA045fE92868821F3eE0a76a5dB38166',
    size: 24,
  },
  {
    key: 'dexscreener',
    name: 'DexScreener',
    url:'https://dexscreener.com/cronos/0x5a2a27235fe78c21d2876bf393c3ebb96ddced52',
    size: 24,
  },
  {
    key: 'wolfswap',
    name: 'WolfSwap',
    url:'https://wolfswap.app/?chainId=25&sellToken=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&buyToken=0x664E4b17EA045fE92868821F3eE0a76a5dB38166',
    size: 24,
  },
  {
    key: 'dextools',
    name: 'DEXTools',
    url:'https://www.dextools.io/app/en/cronos/pair-explorer/0x5a2a27235fe78c21d2876bf393c3ebb96ddced52',
    size: 24,
  },
  {
    key: 'memesoncronos',
    name: 'MemesOnCronos',
    url:'https://www.memesoncronos.com/tokens/robin',
    size: 24,
  },
  {
    key: 'geckoterminal',
    name: 'GeckoTerminal',
    url:'https://www.geckoterminal.com/cro/pools/0x5a2a27235fe78c21d2876bf393c3ebb96ddced52',
    size: 24,
  },
  {
    key: 'xyfinance',
    name: 'XYFinance',
    url:'https://app.xy.finance?fromTokenAddress=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&sourceChainId=1&targetChainId=25&toTokenAddress=0x664E4b17EA045fE92868821F3eE0a76a5dB38166',
    size: 24,
  },
  {
    key: 'coinmarketcap',
    name: 'CoinMarketCap',
    url:'https://coinmarketcap.com/currencies/robin/',
    size: 24,
  },
  {
    key: 'ebisus',
    name: 'EbisusBay',
    url:'https://swap.ebisusbay.com/#/swap?inputCurrency=CRO&outputCurrency=0x664E4b17EA045fE92868821F3eE0a76a5dB38166',
    size: 24,
  },
]

export const Tags: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 520px)')
  return (
    <StyledMarquee>
      {newsItems.map((d:any) => (
        <Link key={d.key} href={`${d.url}`} target="_blank" rel="noopener noreferrer">
          <TagWrapper key={d.key}>
            <Img width={d.size} height={d.size} alt={d.key} src={`/home/partners/${d.key}.png`} />
            <Text css={{fontWeight:500, fontSize: isMobile ? '16px' : '20px', color: d?.color ?? 'invertedContrast'}}>
              {d.name}
            </Text>
          </TagWrapper>
        </Link>
      ))}
    </StyledMarquee>
  )
}

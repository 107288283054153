import React from 'react';
import { styled } from 'stitches.config';

const Background = styled('div', {
  position: 'relative',
  width: '100%',
  minHeight: 200,
  height: '100%',
  overflow: 'hidden',
  '@sm': {
    minHeight: 300
  },
});

const Image = styled('img', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover' // Adjust the image size to cover the entire div
});

const ImageBackground: React.FC = () => {
  return (
    <Background>
      <Image src="/home/bg.png" alt="ROBIN" />
    </Background>
  );
};

export default ImageBackground;

    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[chain]",
      function () {
        return require("private-next-pages/[chain]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[chain]"])
      });
    }
  
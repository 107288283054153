import { NftMint } from './types/types'

const NftMints: NftMint[] = [
  {
    id: 1,
    name: 'Robin NFT',
    description: 'A collection of 2,500 pixelated Robin NFTs. Minting an NFT unlocks exclusive membership benefits, making it a key asset in the $ROBIN space for receiving airdrops, staking rewards, and more!',
    address: '0x1dBA58B1bdcaA3B0488065cCEFa057c52a963280',
    mintAddress: '0x81F1757B71de97b20dE08fC375c2CD5aD051e466',
    createdBy: '0xc850EB4eCa8B8B1464DA1C01e77aF9fd15211d24',
    supply: 2500,
    image: '/robinnft.png',
    banner:'/pixelatedbg.png',
    standard: 'ERC721',
    symbol: 'ROBIN',
    status: 'verified',
    featured: true,
    saleSupply: 2500,
    mintFunction: 'mint',
    pricePerNFT: 100,
    limitPerWallet: 3,
    startTime: 1717862400,
    endTime: 1718035200,
    discordUrl: 'https://discord.gg/HSDJPQTeDX',
    twitterUsername: 'robinCronos',
    externalUrl:'https://robincronos.com/',
    roadmap: [
              {
                name: 'June 8th',
                description: 'NFT Mint',
              },
              {
                  name: 'June 9-10th',
                  description: 'NFT staking, Secondary market trading.',
              },
            ]
  }

  /*** TEST ENV ****/
  /*


  {
    id: 1,
    name: 'Robin NFT',
    description: 'A collection of 2,500 pixelated Robin NFTs. Minting an NFT unlocks exclusive membership benefits, making it a key asset in the $ROBIN space for receiving airdrops, staking rewards, and more!',
    address: '0x8aA5d8c22aeE14974C38e99aDF4a0a6Bf846dB73',
    mintAddress: '0x745fF28Dc03E4dDeBC96cb4006099B6596Be9b9a',
    createdBy: '0x0000000000000000000000000000000000000000',
    supply: 2500,
    image: '/robinnft.png',
    banner:'/pixelatedbg.png',
    standard: 'ERC721',
    symbol: 'ROBIN',
    status: 'verified',
    featured: true,
    saleSupply: 2500,
    mintFunction: 'mint',
    pricePerNFT: 100,
    limitPerWallet: 3,
    startTime: 1717862400,
    endTime: 1718035200,
    discordUrl: 'https://discord.gg/HSDJPQTeDX',
    twitterUsername: 'robinCronos',
    externalUrl:'https://robincronos.com/',
    roadmap: [
              {
                name: 'June 8th',
                description: 'NFT Mint',
              },
              {
                  name: 'June 9-10th',
                  description: 'NFT staking, Secondary market trading.',
              },
            ]
  }

  */
]

export default NftMints
import { Box, Flex, Text } from 'components/primitives'
import { FC } from 'react'
import { useTheme } from 'next-themes'
import ImageBackground from './VideoBackground'

interface IProp {
  hideLink?: boolean
}

const HeroSection: FC<IProp> = ({ hideLink }) => {
  const { theme } = useTheme()
  return (
    <Flex css={{
      alignItems:'center', 
      position:'relative',
      width:'100%', 
      background: theme === 'light' ? '$neutralBg' : 'linear-gradient($neutralBg, rgb(24 35 43))'
      }}>
      <ImageBackground />
      <Box
        css={{
          background:'linear-gradient(to bottom,rgb(1 1 1 / 80%),$neutralBg)',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      />
      <Flex
        css={{
          position: 'absolute',
        //  inlineSize: '97vw',
        // aspectRatio: '1111/1111',
          width: '100%',
          margin:'auto',
          pt:'$5',
          '@sm': {
          pt: '$2',
          },
        //  '@bp500': {aspectRatio: '2300/1180'}
        }}
      >
            <Flex direction={'column'} align={'center'} justify={'center'}
              css={{
                position:'relative',
                width:'auto',
                height: '100%',
                maxWidth: 650,
                margin:'auto',
                gap:'$2'
              }}
            >
            <Text
              as="h3"
              css={{
                fontSize: '1.5rem',
                fontWeight: 700,
                background: 'linear-gradient(45deg, rgb(60 189 76), rgb(166, 18, 0) 80%)',
                // radial-gradient(50% 50% at 50% 50%, rgb(24 35 43) 0%, rgb(71 124 255 / 0%) 100%)
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                '@media (min-width: 768px)': {
                  fontSize: '3rem',
                },
              }}
            >
              Ready for action!
            </Text>
              {/*
                <Text style="h4" as="h4" css={{ flexShrink: 0 }}>
                  <Text style="h4" css={{ color: '$primary9'}}>Discover</Text> presale opportunities
                </Text>
              */}
              <Text style="body1" color="subtle" css={{textAlign:'center'}}>
                A community-driven meme token featuring a Cronos superhero.
              </Text>
            </Flex>
      </Flex>
   </Flex>
  )
}

export default HeroSection
import { FC } from 'react'
import { Text, Box, Flex, Anchor, Button } from '../primitives'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons'

type SectionTitleProps = {
  title: string
}

const SectionTitle: FC<SectionTitleProps> = ({ title }) => (
  <Text style="subtitle1" css={{ color: '$gray12', mb: 8 }}>
    {title}
  </Text>
)

type SectionLinkProps = {
  name: string
  href: string
}

const SectionLink: FC<SectionLinkProps> = ({ name, href }) => (
  <Anchor
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    weight="medium"
    css={{ fontSize: 14, mt: 16 }}
  >
    {name}
  </Anchor>
)

const developerSectionLinks = [
  {
    name: 'Docs',
    href: 'https://docs.robin.space/docs',
  },
  {
    name: 'API Reference',
    href: 'https://docs.robin.space/reference/overview',
  },
  {
    name: 'Github',
    href: 'https://github.com/robinCronos',
  },
  {
    name: 'Testnets',
    href: 'https://testnets.robin.space',
  },
]

const companySectionLinks = [
  {
    name: 'Terms of Use',
    href: 'https://robin.space/terms',
  },
  {
    name: 'Privacy Policy',
    href: 'https://robin.space/privacy',
  },
]

export const Footer = () => {
  return (
    <Flex
      justify="between"
      css={{
        borderTop: '1px solid $gray7',
        borderStyle: 'solid',
        p: '$5',
        '@lg': {
          p: '$6',
        },
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 36,
        '@bp600': {
          flexDirection: 'row',
          gap: 0,
        },
      }}
    >
      {/*
      <Flex css={{ gap: 80, '@bp600': { gap: 136 } }}>
        <Flex direction="column">
          <SectionTitle title="Developers" />
          {developerSectionLinks.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </Flex>
        <Flex direction="column">
          <SectionTitle title="Company" />
          {companySectionLinks.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </Flex>
      </Flex>
          */}
      <Flex
        direction="column"
        css={{ alignItems: 'flex-start', '@bp600': { alignItems: 'flex-end' } }}
      >
        <SectionTitle title="Join our Community" />
        <Flex direction='row' css={{gap:'$3'}}>
          <Flex css={{ gap: '$4', mt: 16 }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://discord.gg/HSDJPQTeDX"
            >
              <Button size="xs" color="gray3">
                <FontAwesomeIcon icon={faDiscord} width={14} height={14} />
              </Button>
            </a>
          </Flex>
          <Flex css={{ gap: '$4', mt: 16 }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://t.me/robinCronos "
            >
              <Button size="xs" color="gray3">
                <FontAwesomeIcon icon={faTelegram} width={14} height={14} />
              </Button>
            </a>
          </Flex>
          <Flex css={{ gap: '$4', mt: 16 }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/robinCronos"
            >
              <Button size="xs" color="gray3">
                <FontAwesomeIcon icon={faXTwitter} width={14} height={14} />
              </Button>
            </a>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
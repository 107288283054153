import { Abi, Address, Chain, formatUnits } from 'viem';
import { useReadContract } from 'wagmi';

export const useFetchBalance = (
  chain: Chain,
  abi: Abi,
  address: Address,
  functionName: string,
  args: any[],
  decimals?: number,
) => {

  const {
    data,
    isFetching,
    refetch,
    isFetchedAfterMount,
  } = useReadContract({
    abi,
    address: address,
    functionName,
    chainId: chain?.id,
    args
  });

  const total =
        typeof data === 'bigint'
            ? +formatUnits(data, decimals || 0)
            : 0
  return {
    total,
    isFetching,
    refetch,
    isFetchedAfterMount
  };
};
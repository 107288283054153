import React, { ComponentPropsWithoutRef } from 'react';
import Flex from './Flex';
import Text from './Text';
import PulseIcon from './Pulse';

type Props = {
  message?: string;
  variant?: string;
  icon?: boolean
} & Pick<ComponentPropsWithoutRef<typeof Flex>, 'css'>;

export default function Tag({ message, variant, css,  icon = true}: Props) {
  // Define the default color and the color based on the variant
  const defaultColor = '$green11';
  const color = variant === 'error' ? '$red11' : defaultColor;

  return (
    <Flex
      css={{
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '16px',
        border: `2px solid ${color}`, // Use dynamic color
        height: 28,
        padding: '0px 8px',
        color: color, // Use dynamic color
        p: '$3',
        gap: '$2',
        ...css,
      }}
      align="center"
    >
      {icon && (
        <svg viewBox="0 0 15 15" width={16} height={16}>
        <path d="M8.34588 13.6273C8.0575 13.8656 7.63823 13.7399 7.49172 13.3958L6.78828 11.7438C7.73587 11.3939 8.62311 10.9234 9.44396 10.3744C9.44396 10.3744 9.66966 11.6436 9.38982 12.3723C9.20938 12.8421 8.73469 13.3061 8.34588 13.6273ZM3.25141 8.2087L1.60451 7.50815C1.25825 7.36086 1.1335 6.93831 1.37577 6.6505C1.69974 6.26564 2.16489 5.79794 2.63298 5.61886C3.35861 5.34125 4.62149 5.55437 4.62149 5.55437C4.07225 6.3748 3.60147 7.26158 3.25141 8.2087ZM12.8903 2.10977C12.8903 2.10977 9.90266 0.830267 6.4865 4.2453C5.1647 5.56643 4.37403 7.02028 3.861 8.29315C3.69201 8.7456 3.80668 9.24027 4.13864 9.57809L5.42423 10.857C5.75619 11.1948 6.25111 11.3034 6.70378 11.1345C8.22388 10.5552 9.6041 9.66087 10.7537 8.51033C14.1699 5.09589 12.8903 2.10977 12.8903 2.10977ZM8.62311 6.3748C8.15233 5.90426 8.15233 5.13812 8.62311 4.66758C9.09389 4.19704 9.86042 4.19704 10.3312 4.66758C10.7959 5.13812 10.802 5.90426 10.3312 6.3748C9.86042 6.84534 9.09389 6.84534 8.62311 6.3748Z" fill="currentColor" />
        <path d="M3.77609 10.3019C3.54971 10.0756 3.23604 9.92849 2.92949 10.0208C2.22706 10.2322 1.71542 10.8838 1.71542 11.6548L1.71542 13.361H3.42253C4.19392 13.361 4.84581 12.8496 5.05736 12.1476C5.14968 11.8412 5.00246 11.5277 4.77608 11.3014L3.77609 10.3019Z" fill="currentColor" />
        </svg>
      )}
      {message === 'Now Live' && !icon && <PulseIcon />}
      <Text style="body3" css={{ color: color }}>
        {message || 'Locked Boost'}
      </Text>
    </Flex>
  );
}